import { apiClient, getRequest, postRequest, patchRequest, deleteRequest, severityCode } from "./httpClient";

const END_POINT = "energy";

// you can pass arguments to use as request parameters/data
// const getRegister = async (buidlingId) => await apiClient.get(END_POINT, { buidlingId });

const getRegisterBySource = async (source, sourceRef) => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT}/register`, { params: { registerSource: source, registerSourceRef: sourceRef } }).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterOverview = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/register/overview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterOverviewFilterOptions = async (fieldName, text) => {
  return await getRequest(`${END_POINT}/register/overview/autocomplete/${fieldName}`, { text: text });
};

const getRegisterDetail = async (registerId) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/register/${registerId}`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterUsageMonthliesOverview = async (registerId, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/register/${registerId}/usageMonthly/overview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterUsageMonthlyOverviewFilterOptions = async (registerId, fieldName, text) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/register/${registerId}/usageMonthly/overview/autocomplete/${fieldName}`, { params: { text: text } }).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterUsageMonthlyDetail = async (registerId, usageId) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/register/${registerId}/usageMonthly/${usageId}`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getRegisterUsageYearly = async (registerId) => {
  return await getRequest(`${END_POINT}/register/${registerId}/usageYearly`);
};

const patchRegisterUsageMonthlyDetail = async (registerId, usageId, data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.patch(`${END_POINT}/register/${registerId}/usageMonthly/${usageId}`, { ...data }).catch((error) => {
    apiError = error;
  });

  if (apiError != undefined) {
    errorResponse = apiError.toString();
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEdsUsages = async (registerId) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/register/${registerId}/RefreshEdsUsages`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response, error: errorResponse };
};

const addRegister = async (data) => {
  //console.log("eu, addRegister: ", data);
  return postRequest(`${END_POINT}/register`, { ...data });
};

const patchRegister = async (registerId, data) => {
  //console.log("eu, patchRegister: ", registerId, data);
  return patchRequest(`${END_POINT}/register/${registerId}`, { ...data });
};

const addRegisterYearUsage = async (registerId, data) => {
  //console.log("eu, addRegisterYearUsage: ", data);
  return postRequest(`${END_POINT}/register/${registerId}/yearusage`, { ...data });
};

const patchRegisterYearUsage = async (registerId, usageId, data) => {
  //console.log("eu, patchRegisterYearUsage: ", registerId, usageId, data);
  return patchRequest(`${END_POINT}/register/${registerId}/yearusage/${usageId}`, { ...data });
};

const delRegisterYearUsage = async (registerId, year) => {
  //console.log("eu, delRegisterYearUsage: ",year);
  return deleteRequest(`${END_POINT}/register/${registerId}/yearusage/${year}`);
};

export {
  getRegisterBySource,
  getRegisterOverview,
  getRegisterOverviewFilterOptions,
  getRegisterDetail,
  getRegisterUsageMonthliesOverview,
  getRegisterUsageMonthlyOverviewFilterOptions,
  getRegisterUsageMonthlyDetail,
  getRegisterUsageYearly,
  patchRegisterUsageMonthlyDetail,
  getEdsUsages,
  addRegister,
  patchRegister,
  addRegisterYearUsage,
  patchRegisterYearUsage,
  delRegisterYearUsage,
  severityCode,
};
